<template>

  <div class="main-content">

  <LanguageSwitcher>
  </LanguageSwitcher>

  <h1 class="header">{{ t('welcome') }}</h1>
  <div class="main-description">
    <p><b>{{ t('main-description') }}</b></p>
  </div>

    <div class="footer-parent">
      <div class="footer-child">
        <p>
          <a href="https://www.instagram.com/lettia_hotel?igsh=bWN3M2VqZWlwbjNl" target="_blank"><FontAwesomeIcon :icon="faInstagram"/></a>
        </p>
      </div>
      <div class="footer-child">
        <p>
          <a href="mailto:info@hotellettia.com" target="_blank"><FontAwesomeIcon :icon="faEnvelope"/></a>
        </p>
      </div>
      <div class="contact">
        <p>
          Jomas iela 77<br>
          Jūrmala, LV-2015<br>
          tel. +371 25549977<br>
        </p>
      </div>
    </div>

  </div>

</template>

<script setup>

import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import {useI18n} from 'vue-i18n';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

const {t} = useI18n();
</script>

<!--<i18n>-->
<!--{-->
<!--  "en": {-->
<!--    "hello": "Hello World!"-->
<!--  },-->
<!--  "de": {-->
<!--    "hello": "Hallo Welt!"-->
<!--  },-->
<!--  "ru": {-->
<!--    "hello": "Привет, мир!"-->
<!--  }-->
<!--}-->
<!--</i18n>-->

<style>

a {
  color: inherit;
}

.contact {
  font-size: 0.7rem;
}

.header {
  margin: 1rem;
  /*background-color: rgb(109, 204, 21);*/
}

.main-description {
  margin: 2rem;
  padding: 1rem;
  border-radius: 1rem;
  color: #30217E;
  background-color: rgba(255, 255, 255, 0.7);
}

.footer-parent {

  width: 100%;
  color: #fff;
  height: 12.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-child {
  margin: 0.4rem;
  display: inline-block;
}

.main-content {
  font-size: 1rem;
  color: #fff;
  z-index: 100;
  height: 100%;
  width: 100%;
  box-sizing: content-box;
  /*background-color: rgba(222, 45, 213, 0.6);*/
  position: absolute;
  top: 0;
  left: 0;
}

</style>
