<template>
  <div class="buttons">
    <button @click="setLanguage('en')"><b>English</b></button>
    <button @click="setLanguage('ru')"><b>Русский</b></button>
    <button @click="setLanguage('ee')"><b>Eesti</b></button>
    <button @click="setLanguage('lv')"><b>Latviski</b></button>
    <button @click="setLanguage('lt')"><b>Lietuvių</b></button>
    <button @click="setLanguage('de')"><b>Deutsch</b></button>
  </div>
</template>

<script setup>

import {useI18n} from 'vue-i18n';
import {watchEffect} from 'vue';

const {locale} = useI18n();

const setLanguage = (newLang) => {
  locale.value = newLang;
  localStorage.setItem('lang', newLang);
};

watchEffect(() => {
  const storedLang = localStorage.getItem('lang');
  if (storedLang) {
    locale.value = storedLang;
  }
});


</script>

<style>

.buttons {
  padding: 0.6rem;
  /*background-color: rgba(90, 68, 158, 0.6);*/
}

button {
  font-size: 0.7rem;
  width: 4rem;
  border-radius: 1rem;
  margin: 0.2rem;
  border: none;
  text-align: center;
  color: #30217E;
  background-color: rgba(255, 255, 255, 0.7);

}

</style>