<template>
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->

  <div class="container">
    <div class="overlay"></div>
    <img alt="05" src="./assets/welcome-05.png">
    <img alt="04" src="./assets/welcome-04.png">
    <img alt="01" src="./assets/welcome-01.png">
    <img alt="03" src="./assets/welcome-03.png">
    <MainContent></MainContent>
  </div>
</template>

<script>
import MainContent from "@/components/MainContent.vue";

export default {
  name: 'App',
  components: {
    MainContent: MainContent

  }
}
</script>

<style>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  height: 100vh;
  width: 100%;
  position: relative;
}



.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  animation: fade 48s ease-in-out infinite alternate;
}

img:nth-of-type(1){
  animation-delay: -0s;
}
img:nth-of-type(2){
  animation-delay: -36s;
}
img:nth-of-type(3){
  animation-delay: -24s;
}
img:nth-of-type(4){
  animation-delay: -12s;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
